/* Start Pool Tabs */
.pool-main{
    @apply min-h-full py-5;
}
.pool-main .pool-tabs{
    @apply flex items-center gap-x-4;
}
.pool-main .pool-tabs .tab-item{
    @apply flex items-center text-sm m-0;
    font-weight: var(--fw-600);
    color: var(--text-dark-500);
}
.pool-main .pool-tabs .tab-item.active{
    color: var(--text-white-200);
}
.pool-main .pool-tabs .tab-item .count{
    @apply flex items-center justify-center w-5 h-5 text-xs rounded-full ml-2;
    font-weight: var(--fw-400);
    color: var(--text-warning-300);
    background-color: var(--bg-warning-300);
}
/* Start Pool Tabs */

/* Start Pool Filters */
.pool-main .view-filters{
    @apply flex items-center;
}
.pool-main .view-filters .btn-filter{
    @apply inline-flex items-center text-xs leading-none px-2.5 py-2;
    font-weight: var(--fw-500);
    color: var(--text-dark-500);
    background: var(--bg-dark-1600);
    border: 1px solid rgba(255, 255, 255, 0.05);
}
.pool-main .view-filters .btn-filter:first-of-type{
    border-radius: 4px 0px 0px 4px;
}
.pool-main .view-filters .btn-filter:last-of-type{
    border-radius: 0px 4px 4px 0px;
}
.pool-main .view-filters .btn-filter.active{
    color: var(--text-white-200);
    background: var(--bg-dark-1700);
}
/* End Pool Filters */

/* Start Pool List View */
.pool-main .pool-list{
    @apply rounded-md;
    width: 100%;
    padding: 18px 18px 24px 18px;
    background: var(--bg-dark-1400);
    border: 1px solid rgb(255 255 255 / 7%);
    backdrop-filter: blur(2px);
}
.pool-table{
    @apply w-full;
}
.pool-table tr th{
    @apply px-2.5 py-3.5 text-xs text-left whitespace-nowrap;
    font-weight: var(--fw-600);
    color: var(--text-dark-500);
}
.pool-table tr td{
    @apply px-2.5 py-6 text-sm whitespace-nowrap;
    font-weight: var(--fw-600);
    color: var(--text-white-200);
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.pool-table tr td .conversion{
    @apply flex items-center;
}
.pool-table tr td .icons{
    @apply flex items-center;
}
.pool-table tr td .icons img{
    border-radius: 100%;
    border: 1px solid #222;
    @apply relative inline rounded-full;
    width: 26px;
    min-width: 26px;
}
.pool-table tr td .icons img:nth-of-type(2){
    left: -9px;
}
.pool-table tr td .fee{
    @apply px-2 py-0.5 rounded-lg ml-2;
    font-size: 10px;
    font-weight: var(--fw-400);
    color: var(--text-white-800);
    background: var(--bg-dark-1500);
}
/* End Pool List View */

/* Start Pool Grid View */
.pool-main .pool-grid-box{
    @apply rounded-md;
    width: 100%;
    padding: 25px 16px 20px;
    background: var(--bg-dark-1400);
    border: 1px solid rgb(255 255 255 / 7%);
    backdrop-filter: blur(2px);
}
.pool-grid-box .conversion{
    @apply flex items-center mb-10;
}
.pool-grid-box .icons{
    @apply flex items-center;
}
.pool-grid-box .icons img{
    border-radius: 100%;
    border: 1px solid #222;
    @apply relative inline rounded-full;
    width: 26px;
    min-width: 26px;
}
.pool-grid-box .icons img:nth-of-type(2){
    left: -9px;
}
.pool-grid-box .coins{
    @apply text-base leading-none;
    font-weight: var(--fw-600);
    color: var(--text-white-200);
}
.pool-grid-box .fee{
    @apply px-2 py-1 rounded-lg ml-2;
    font-size: 10px;
    font-weight: var(--fw-400);
    color: var(--text-white-800);
    background: var(--bg-dark-1500);
}
.pool-grid-box .details{
    @apply flex items-start justify-between;
}
.pool-grid-box .details .item .header-text{
    @apply text-xs leading-none mb-3.5;
    font-weight: var(--fw-600);
    color: var(--text-dark-300);
}
.pool-grid-box .details .item .body-text{
    @apply text-sm leading-none;
    font-weight: var(--fw-600);
    color: var(--text-white-200);
}
/* End Pool Grid View */

/* Start Pool Details Header */
.pool-details-header{
    @apply block md:flex items-center justify-between mb-9 md:mb-7;
}
.pool-details-header .pool-info{
    @apply w-full md:w-auto inline-flex items-center mb-4 md:mb-0;
}
.pool-details-header .pool-info .icons{
    @apply flex items-center;
}
.pool-details-header .pool-info .icons img{
    border-radius: 100%;
    border: 1px solid #222;
    @apply relative w-8 md:w-11 inline rounded-full;
}
.pool-details-header .pool-info .icons img:nth-of-type(2){
    left: -15px;
}
.pool-details-header .pool-info .coins{
    @apply flex items-center text-xl md:text-2xl leading-none md:leading-none mb-0 md:mb-1.5;
    font-weight: var(--fw-600);
    color: var(--text-white-400);
}
.pool-details-header .pool-info .fee{
    @apply text-sm leading-none mb-0;
    font-weight: var(--fw-600);
    color: var(--text-dark-500);
}
.pool-details-header .action-btns{
    @apply flex items-center justify-between md:justify-end gap-x-1.5 sm:gap-x-2;
}
.pool-details-header .action-btns .header-btn{
    @apply w-full md:w-auto text-xs whitespace-nowrap text-center px-2 md:px-3 py-3 rounded-lg;
    font-weight: var(--fw-600);
    color: var(--text-dark-600);
    background: var(--bg-dark-1800);
    border: 1px solid rgba(255, 255, 255, 0.06);
    cursor: default;
}
/* Start Pool Details Header */

/* Start Pool Chart */
.pool-chart .chart-links{
    @apply hidden md:flex items-center justify-between mb-2;
}
.pool-chart .chart-links .chart-pill{
    @apply text-sm px-3 py-2.5 rounded-full leading-none no-underline;
    font-weight: var(--fw-600);
    color: rgba(255, 255, 255, 0.24);
    background-color: transparent;
}
.pool-chart .chart-links .chart-pill.active{
    color: var(--text-warning-500);
    background-color: var(--bg-warning-500);
}

.pool-chart .sm-chart-info{
    @apply flex md:hidden items-center justify-between mb-6;
}
.sm-chart-info .heading{
    @apply text-xs leading-none;
    font-weight: var(--fw-600);
    color: rgba(255, 255, 255, 0.24);
}
.sm-chart-info .action-dd{
    @apply relative;
}
.sm-chart-info .action-dd .btn-select{
    @apply flex items-center gap-x-3 px-3 py-2 text-xs rounded-full;
    font-weight: var(--fw-600);
    color: #D9D2D2;
    background-color: rgba(113, 113, 121, 0.12);
}
.sm-chart-info .action-dd .dd-list{
    @apply w-full absolute right-0 z-10 mt-2 origin-top-right p-2 rounded-md;
    background-color: #160f0c;
    border: 1px solid rgba(255, 255, 255, 0.04);
}
.sm-chart-info .action-dd .dd-list .item{
    @apply block text-xs mb-1.5;
    font-weight: var(--fw-500);
    color: #D9D2D2;
}
.sm-chart-info .action-dd .dd-list .item:last-child{
    @apply mb-0;
}
/* End Pool Chart */

.single-pool{
    @apply py-5;
}
.single-pool hr{
    @apply w-full h-0.5;
    background-color: rgba(255, 255, 255, 0.06);
}

/* Start Pool Summary Widget Box */
.summary-widget{
    @apply rounded-md py-3.5 px-3;
    background: var(--bg-dark-1900);
    border: 1px solid rgb(255 255 255 / 7%);
    backdrop-filter: blur(10px);
    cursor: default;
}
.summary-widget .key{
    @apply text-xs leading-none mb-3;
    font-weight: var(--fw-500);
    color: rgba(148, 163, 184, 0.78);
}
.summary-widget .value{
    @apply text-base leading-none mb-3;
    font-weight: var(--fw-600);
    color: var(--text-white-900);
}
.summary-widget .percentage{
    @apply text-xs leading-none mb-0;
    font-weight: var(--fw-500);
    color: #FFCB8F;
}
.summary-widget .percentage.positive{
    color: #8EFFB8 !important;
}
.summary-widget .percentage.negative{
    color: #FF8383 !important;
}
/* End Pool Summary Widget Box */

/* Start Pool Liquidity Card */
.liquidity-card{
    @apply relative w-full px-3.5 md:px-5 pt-5 pb-4 md:py-6 rounded-lg;
    width: 100%;
    background-color: var(--bg-dark-700);
    border: 1px solid rgba(255, 255, 255, 0.07);
    backdrop-filter: blur(8px);
}
.liquidity-card .tabs{
    @apply flex items-center gap-x-6 pb-3.5 mb-4;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
    justify-content: space-between;
}
.liquidity-card .tabs .tab-item{
    @apply text-base md:text-lg leading-none md:leading-none m-0;
    font-weight: var(--fw-600);
    color: var(--text-dark-500);
}
.liquidity-card .tabs .tab-item.active{
    color: var(--text-white-100);
}

/* Add Liquidity */
.add-liquidity .swap-container .swapable{
    @apply rounded-2xl px-4 pt-3 pb-5;
    background: var(--bg-dark-1300);
    border: 1px solid rgba(0, 0, 0, 0.07);
}
.add-liquidity .swap-container .swapable .sm-text{
    @apply text-xs leading-none mb-0;
    font-weight: var(--fw-700);
    color: var(--text-dark-500);
}
.add-liquidity .swap-container .swapable .amount{
    @apply block bg-transparent text-2xl leading-none p-0 border-0 outline-0 shadow-none;
    width: 80%;
    font-weight: var(--fw-600);
    color: var(--text-white-200);
}
.add-liquidity .swap-container .swapable .amount:focus{
    outline: 0 ;
    box-shadow: none ;
}
.add-liquidity .swap-container .swapable .amount::placeholder{
    color: var(--text-dark-500);
}
.add-liquidity .swap-container .swapable .amount::-webkit-input-placeholder {
    color: var(--text-dark-500);
}
.add-liquidity .swap-container .swapable .amount::-moz-placeholder {
    color: var(--text-dark-500);
}
.add-liquidity .swap-container .swapable .amount:-ms-input-placeholder {
    color: var(--text-dark-500);
}
.add-liquidity .swap-container .swapable .amount:-moz-placeholder {
    color: var(--text-dark-500);
}
.add-liquidity .swap-container .swapable .balance{
    @apply text-sm md:text-base leading-none md:leading-none mb-0;
    font-weight: var(--fw-500);
    color: rgb(117 129 145 / 80%);
}
.add-liquidity .swap-container .swapable .selected-coin{
    @apply flex items-center;
}
.add-liquidity .swap-container .swapable .selected-coin .icon{
    border-radius: 100%;
    border: 1px solid #222;
    @apply mr-1.5 md:mr-2.5 w-5 md:w-6;
}
.add-liquidity .swap-container .swapable .selected-coin .currency{
    @apply text-sm md:text-base mb-0;
    font-weight: var(--fw-600);
    color: var(--text-white-600);
}
.add-liquidity .swap-container .btn-swap{
    @apply absolute left-1/2 flex items-center justify-center rounded-md md:rounded-lg shadow-none outline-0;
    top: calc(50% + 3px);
    border: 1px solid rgb(255 255 255 / 3%);
    border-radius: 10px;
    background-color: rgb(31 33 34);
    color: #999;
    transform: translate(-50%, -50%);
    height: 40px;
    width: 56px;
}
.add-liquidity .swap-container .btn-swap .icon{
    @apply w-2.5 md:w-4;
}

.add-liquidity .collapsible-pool-details{
    @apply w-full p-3;
    background-color: rgba(244, 244, 245, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 10px;
}
.add-liquidity .collapsible-pool-details .collapse-header{
    @apply w-full flex items-center justify-between gap-x-5 text-sm md:text-base;
    font-weight: var(--fw-600);
    color: var(--text-white-700);
}
.add-liquidity .collapsible-pool-details #collapsible-details{
    @apply pt-5;
}
.add-liquidity .collapsible-pool-details #collapsible-details .key{
    @apply text-sm capitalize leading-none mb-0;
    font-weight: var(--fw-400);
}
.add-liquidity .collapsible-pool-details #collapsible-details .value{
    @apply text-sm uppercase text-right leading-none mb-0;
    font-weight: var(--fw-400);
}
/* Add Liquidity */

/* My Liquidity */
.my-liquidity .swap-container .swapable{
    @apply rounded-2xl px-4 pt-3 pb-5;
    background: var(--bg-dark-1300);
    border: 1px solid rgba(0, 0, 0, 0.07);
}
.my-liquidity .swap-container .swapable .sm-text{
    @apply text-xs leading-none mb-0;
    font-weight: var(--fw-700);
    color: var(--text-dark-500);
}
.my-liquidity .swap-container .swapable .amount{
    @apply text-2xl leading-none mb-2;
    font-weight: var(--fw-600);
    color: var(--text-white-200);
}
.my-liquidity .swap-container .swapable .balance{
    @apply text-sm md:text-base leading-none md:leading-none mb-0;
    font-weight: var(--fw-500);
    color: rgb(117 129 145 / 80%);
}
.my-liquidity .swap-container .swapable .selected-coin{
    @apply flex items-center mb-0.5;
}
.my-liquidity .swap-container .swapable .selected-coin .icon{
    border-radius: 100%;
    border: 1px solid #222;
    @apply mr-1.5 md:mr-2.5 w-5 md:w-6;
}
.my-liquidity .swap-container .swapable .selected-coin .currency{
    @apply text-sm md:text-base leading-none md:leading-none mb-0;
    font-weight: var(--fw-600);
    color: var(--text-white-600);
}
.my-liquidity .swap-container .swapable .fee{
    @apply text-sm text-right leading-none mb-0;
    font-weight: var(--fw-600);
    color: var(--text-warning-100);
}

.my-liquidity .collapsible-pool-details{
    @apply w-full p-3;
    background-color: rgba(244, 244, 245, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 10px;
}
.my-liquidity .collapsible-pool-details .collapse-header{
    @apply w-full flex items-center justify-between gap-x-5 text-sm md:text-base;
    font-weight: var(--fw-600);
    color: var(--text-warning-300);
    
}
.my-liquidity .collapsible-pool-details #collapsible-details{
    @apply pt-5;
}
.my-liquidity .collapsible-pool-details #collapsible-details .balance-bar{
    @apply flex items-center justify-between p-2.5 rounded-md mb-4 md:mb-6;
    background-color: var(--bg-dark-300);
}
.my-liquidity .collapsible-pool-details #collapsible-details .balance-bar .key,
.my-liquidity .collapsible-pool-details #collapsible-details .balance-bar .value{
    @apply text-sm leading-none mb-0;
    font-weight: var(--fw-400);
    color: var(--text-dark-500);
}
.my-liquidity .collapsible-pool-details #collapsible-details .will-remove-card{
    @apply rounded-lg mt-4 md:mt-6 overflow-hidden;
    border: 1px solid rgba(255, 255, 255, 0.08);
}
.my-liquidity .collapsible-pool-details #collapsible-details .will-remove-card .header{
    @apply text-xs py-2.5 px-3;
    font-weight: var(--fw-400);
    color: var(--text-warning-300);
    background-color: var(--bg-warning-200);
}
.my-liquidity .collapsible-pool-details #collapsible-details .will-remove-card .key{
    @apply text-sm leading-none mb-0;
    font-weight: var(--fw-400);
}
.my-liquidity .collapsible-pool-details #collapsible-details .will-remove-card .value{
    @apply text-sm text-right leading-none mb-0;
    font-weight: var(--fw-400);
}

.my-liquidity .claim-rewards-card{
    @apply px-3 pt-4 pb-3 mt-4 md:mt-9;
    background-color: var(--bg-dark-1800);
    border-radius: 10px;
}
.my-liquidity .claim-rewards-card .header{
    @apply text-base leading-none pb-3.5 mb-4;
    font-weight: var(--fw-600);
    color: var(--text-white-700);
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
}
.my-liquidity .claim-rewards-card .key{
    @apply text-sm leading-none mb-0;
    font-weight: var(--fw-400);
}
.my-liquidity .claim-rewards-card .value{
    @apply text-sm text-right leading-none mb-0;
    font-weight: var(--fw-400);
}
/* End Pool Liquidity Card */

/* Start Pool - Postion Card */
.single-pool .position-card{
    @apply w-full px-4 md:px-6 py-5 rounded-lg;
    background-color: var(--bg-dark-700);
    border: 1px solid rgba(255, 255, 255, 0.07);
}
.position-card .header{
    @apply flex items-center justify-between pb-4 mb-4;
    border-bottom: 1px solid rgba(226, 232, 240, 0.05);
}
.position-card .header .heading{
    @apply text-xs leading-none mb-0;
    font-weight: var(--fw-600);
    color: var(--text-white-900);
}
.position-card .header .value{
    @apply text-xl leading-none mb-0;
    font-weight: var(--fw-600);
    color: var(--text-dark-500);
}
.position-card .coin .name{
    @apply text-sm leading-none mb-0;
    font-weight: var(--fw-500);
    color: var(--text-white-200);
}
.position-card .amount{
    @apply text-xs leading-none mb-0;
    font-weight: var(--fw-500);
    color: var(--text-dark-500);
}
/* End Pool - Postion Card */

/* Start Pool - In Case Card */
.single-pool .in-case-card{
    @apply w-full px-4 md:px-6 py-5 rounded-lg;
    background-color: var(--bg-dark-700);
    border: 1px solid rgba(255, 255, 255, 0.07);
}
.single-pool .in-case-card .header{
    @apply flex items-center text-sm mb-3.5;
    font-weight: var(--fw-600);
    color: var(--text-warning-300);
}
.single-pool .in-case-card .desc{
    @apply text-sm leading-6 mb-0;
    font-weight: var(--fw-600);
    color: var(--text-dark-300);
}
/* End Pool - In Case Card */

.coin-two {
    border-radius: 100%;
    border: 1px solid #222;
}
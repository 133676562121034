@tailwind base;
@tailwind components;
@tailwind utilities;
@import './components/loader.css';
@import './components/global.css';
@import './components/navbar.css';
@import './components/rightSidebar.css';
@import './components/swap.css';
@import './components/stake.css';
@import './components/pool.css';
@import './components/farm.css';
@import './components/ido.css';
@import './components/faucet.css';

body {
    font-family: 'Inter', sans-serif !important;
    background: linear-gradient(180deg, #1F1410 0%, #000000 80.5%) #000000;
    height: 100vh;
    background-attachment: fixed;
    overflow-x: hidden;
}
.bg-velar-gradient {
    background: linear-gradient(180deg, #1F1410 0%, #000000 80.5%);
}
/* popover content */
.popover > .popover-content {
    transform: scale(0);
    opacity: 0;
}
/* popover button svg transform rotate */
.popover > .popover-button svg {
    transition: transform 0.15s ease-out;
}
.popover.popover-active > .popover-button svg {
    transform: rotate(180deg);
}

@layer components {
    /* buttons */
    .btn-primary {
        @apply bg-primary text-btn border border-solid border-primaryDark font-bold leading-5 py-2.5 px-4 rounded-[32px] relative transition-all ease-in duration-200 hover:pr-8;
    }
    .btn-primary::after {
        content: url(/assets/icons/arrow.svg);
        @apply absolute transition-all ease-in duration-200 w-0 opacity-0;
    }
    .btn-primary:hover::after {
        @apply pl-2 w-auto opacity-100;
    }
    .btn-primary.text-sm::after {
        @apply leading-6;
    }
    .btn-secondary {
        @apply font-bold text-xs lg:text-sm p-2.5  border border-solid border-[#353738] hover:border-white/20 rounded-full bg-[#262626] flex items-center gap-2 ease-in duration-150;
    }
    .btn-secondary-two {
        @apply inline-flex items-center text-dull-100 hover:text-dull-100/90 bg-dull-200 hover:bg-dull-200/70 px-4 border-[1px] border-dull-100/20 rounded-lg font-bold;
    }
    .btn-sec-two-mb {
        @apply inline-flex items-end text-[#7B7978] text-lg px-4 font-bold;
    }
    .chart-pill.active {
        --tw-bg-opacity: 1;
        background-color: rgb(120 76 25 / var(--tw-bg-opacity));
        --tw-text-opacity: 1;
        color: rgb(248 157 51 / var(--tw-text-opacity));
    }
    .chart-pill {
        @apply mt-2 md:mt-0 px-3 py-2 text-poolText hover:text-primary hover:bg-primaryDark rounded-full rounded-full;
        display: block;
        width: max-content;
    }
    .chart-pill-active {
        @apply px-3 py-2 text-primary bg-primaryDark rounded-md md:rounded-full;
    }
    
    /* navbar */
    .nav-item{
        @apply text-white/25 px-3 py-2 text-sm font-medium;
    }
    .nav-item.active{
        @apply text-primary px-3 py-2 text-sm font-medium;
    }

    /* navbar bell icon notification */
    .notify{
        @apply absolute bottom-0 left-auto right-0 top-auto z-10 inline-block translate-x-0 translate-y-0 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 rounded-full bg-primary p-1 text-xs;
    }

    /* Page sections */
    .page-heading {
        @apply text-white text-2xl md:text-[2.6rem] font-bold mb-2;
    }
    .page-sub-heading {
        @apply text-base md:text-xl text-gray mb-6;
    }

    /* Global buttons define */    
    .btn-primary-1 {
        @apply my-3 block py-2 text-center text-primary bg-cream-600 rounded-full font-medium;
    }
    .btn-danger-1 {
        @apply block py-2 text-center text-pink-100 bg-pink-200  my-3 rounded-full font-medium;
    }

    /* Teams page */
    .btn-arrow-one {
        @apply bg-[#FCE1C2] text-btn border border-solid border-primaryDark font-bold leading-5 py-2.5 px-4 rounded-[32px] relative transition-all ease-in duration-200 hover:pr-8;
    }
    .btn-arrow-two {
        @apply bg-primary text-btn border border-solid border-primaryDark font-bold leading-5 py-2.5 px-4 rounded-[32px] relative transition-all ease-in duration-200 hover:pr-8;
    }
    .btn-arrow-three {
        @apply bg-primary text-btn border border-solid border-primaryDark font-bold leading-5 py-2.5 px-4 rounded-[32px] relative transition-all ease-in duration-200 hover:pr-8;
    }
    
}

.actionsheet-bar {
    height: 8px;
    width: 50px;
    background-color: #fbfbfb;
    margin: 0 auto;  
    border-radius: 10px;
    margin-top: 12px;
    margin-bottom: 12px;
    cursor: move;
}

.hand {
    cursor: pointer;
}

.rangeslider-horizontal .rangeslider__handle::after {
    display: none;
}

.rangeslider-horizontal {
    height: 8px !important;
    margin: 0 !important;
    border-radius: 4px !important;
    background: rgba(226, 232, 240, 0.05) !important;
    box-shadow: none !important;
}
.range-percentage{
    color: var(--text-dark-500);
}

.rangeslider-horizontal .rangeslider__handle {
    width: 18px !important;
    height: 18px !important;
}

.rangeslider .rangeslider__handle {
    background: #F89D33 !important;
    border: 3px solid rgba(255, 255, 255, 0.5) !important;
    /* border: 1px solid #F89D33 !important; */
}

.rangeslider-horizontal .rangeslider__fill {
    background: #F89D33 !important;
}

.rangeslider {
    background-color: #1a1b1c !important;
}

@media (min-width: 768px) {
    .md\:flex {
        display: flex !important;
    }
}

.full-wrapper {
    position: fixed;
    inset: 0px;
    background-color: rgba(1, 1, 1, 0.8);
    backface-visibility: hidden;
    transition: opacity 0.5s ease-in-out 0s, z-index 0.5s ease-in-out 0s;
    opacity: 1;
    z-index: 9;
}

.full-wrapper-blank {
    position: fixed;
    inset: 0px;
    background-color: transparent;
    backface-visibility: hidden;
    transition: opacity 0.5s ease-in-out 0s, z-index 0.5s ease-in-out 0s;
    opacity: 1;
    z-index: 10;
}

@media (min-width: 1024px) {
    .lg\:block {
        display: block !important;
    }
}

.md:inline-flex {
    display: inline-flex !important;
}


input.noarrow::-webkit-outer-spin-button,
input.noarrow::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input.noarrow[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.noti-tab-active { 
    color: rgb(255 255 255 / var(--tw-text-opacity));
}


.notification-badge {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    text-align: center;
    color: rgba(255, 255, 255, 0.38);
    background: rgba(255, 255, 255, 0.07);
    border-radius: 12px;
    opacity: 0.8;
    min-width: 65px;
    padding: 2px 6px;
}

.notification-badge.processing {
    color: #F89D33;
    background: rgba(248, 157, 51, 0.15);;
}

.notification-badge.failed {
    color: #f03545;
    background: rgba(248, 157, 51, 0.15);;
}




.spin {
    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}
  
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
.na {
    display: none;
}
.nt:hover .na {
    display: block;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.cusror-default {
    cursor: default !important;
}

.delete-icon {
    width: 15px;
    height: 15px;
    border-radius: 1000%;
    display: inline-block;
    background: #cd4f4f;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 12px;
    position: absolute;
    top: 60%;
    right: 5%;
    cursor: pointer;
    color: #111;
}

.badge-velar {
    color: white !important;
    background-color: #cd4f4f !important;
}